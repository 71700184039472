<template>
  <div>
    <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>账户充值  </div>
    <div class="main">
        <el-row :gutter="10">
            <el-col :span="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>请充值到以下对公账户</span>
                    </div>
                    <div  class="text">
                        <p> <span>开户银行</span>{{account.platformOpenBank}} </p>
                        <p> <span>商户平台帐户户名</span>{{account.platformAccountName}} </p>
                        <p> <span>商户平台帐户账号</span>{{account.platformAccountNo}} </p>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>使用以下指定账户充值</span>
                     </div>
                    <div  class="text1">
                            <p> <span>开户银行</span>{{account.merchantOpenBank}} </p>
                            <p> <span>帐户名称</span>{{account.merchantAccountName}} </p>
                            <p> <span>对公账户</span>{{account.merchantAccountNo}} </p>
                    </div>
                </el-card>
            </el-col>
        </el-row>
     </div>
    
  </div>
</template>
<script>
import {merchantAccountInfo} from  "@/api/captalInterface";
export default {
  components: {},
  data() {
    return {
      account:{}
    };
  },
  activated(){
    this.AccountInfo()
  },
  mounted(){
     this.AccountInfo()
  },
  methods:{
      AccountInfo(){
          merchantAccountInfo({}).then((data=>{
                if (data.code==200) {
                  this.account=data.data
                }
          }))
      }
  }
};
</script>
<style scoped>
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
.main{
    margin-top: 10px;
}
.clearfix span{
     font-size: 14px;
}
.text .text1{
     margin-top: -10px;
}
.text span{
    display: inline-block;
    width:120px;
    font-size: 12px;
    text-align: right;
    /* background: darkcyan; */
    margin-right: 20px;
}
.text1 span{
    display: inline-block;
    width:70px;
    font-size: 12px;
    text-align: right;
    /* background: darkcyan; */
    margin-right: 20px;
}

</style>
